<script setup lang="ts">
import type { Product } from 'types/models/product'

interface Props {
  products: Product[]
  hideCardInfo: boolean
}

const props = defineProps<Props>()
</script>

<template>
  <TransitionGroup
    name="list"
    tag="ul"
    mode="out-in"
    class="color-selector-cards"
  >
    <li
      v-for="product in props.products"
      :key="product.id"
      class="color-card"
    >
      <ProductColorSelectorCard
        :product="product"
        :hide-card-info="hideCardInfo"
      />
    </li>
  </TransitionGroup>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/body';
.color-card {
  display: flex;
  flex: 1 1 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
</style>
