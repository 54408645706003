<script setup lang="ts">
import type { Product } from 'types/models/product'
import { useMediaQuery } from '@vueuse/core'
import { productUrl } from 'lib/routing'

interface Props {
  product: Product
  hideCardInfo: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['click'])
const route = useRoute()

const imageUrl = computed(() => {
  return props.product.media.images[0]?.url || props.product.media.hero.thumbnail?.url || ''
})

const imageAlt = computed(() => {
  return props.product.media.images[0]?.alt || props.product.media.hero.thumbnail?.label || ''
})

const productColor = computed(() => {
  return props.product.color.primary ? props.product.color.primary : ''
})

const stockStatus = computed(() => {
  return props.product.stockStatus === 'low-stock' ? 'fewLeft' : 'outOfStock'
})

const isLargeScreen = useMediaQuery('(min-width: 1024px)')
</script>

<template>
  <NuxtLink
    v-slot="{ isExactActive }"
    class="color-selector-card"
    :aria-label="`${$t(productColor)}`"
    :to="{
      path: productUrl(product.sku),
      query: { ...route.query },
    }"
    @click.prevent="emit('click')"
  >
    <ImageResponsive
      class="card-image"
      :class="[{ blur: product.stockStatus !== 'in-stock' }]"
      provider="fastly"
      :src="imageUrl"
      :alt="imageAlt"
      background-color="transparent"
      object-fit="cover"
      :height="isLargeScreen ? '127px' : '80px'"
      :max-width="320"
    />
    <div class="card-info">
      <span
        class="text"
        :class="[{ 'hidden-text': !isExactActive && hideCardInfo }]"
      >
        {{ $t(productColor) }}
      </span>
      <span
        v-if="product.stockStatus !== 'in-stock'"
        class="stock__label"
        :class="[product.stockStatus === 'low-stock' ? 'few-left' : 'out-stock']"
      >
        {{ $t(stockStatus) }}
      </span>
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/breakpoints';

.card-info {
  text-align: center;
  display: flex;
  flex-direction: column;

  .text {
    @include inputText;
    margin-top: 0.5rem;
  }
}

.card-image {
  align-items: center;

  :deep(img) {
    width: auto;
    padding-bottom: 1rem;
    margin: 0 auto;
  }
}
.color-selector-card:focus-visible {
  outline: 2px solid var(--black);
}

.color-selector-card:hover,
.router-link-exact-active {
  .card-image {
    line-height: 2.5rem;
    background-image: url('/icons/wavyLineBlue.svg');
    position: relative;
    background-repeat: repeat-x;
    background-position-y: bottom;
  }
}

.color-selector-card .hidden-text {
  visibility: hidden;
}
.color-selector-card:hover .hidden-text {
  visibility: visible;
}

.stock {
  &__label {
    @include helperText;
    padding: 0 1px;

    &.out-stock {
      color: #6B6B6B;
    }
    &.few-left {
      color: var(--orange-warning);
    }
  }
}

.blur :deep(.visible) {
  opacity: 0.5;
}
</style>
